<template>
  <auth-layout sidebar>
    <div class="flex-wrapper-full-background d-flex fill-height" style="width: 100%">
      <transition name="fade">
        <v-card
          v-if="!loader"
          class="mx-auto my-auto login-card py-4"
          flat
          color="transparent"
          max-width="530"
          raised>
          <h2 class="text--h2 login-header d-flex align-center px-sm-6">
            <span>Welcome to</span>
            <img
              src="@/assets/img/logo/logo-colored.svg"
              alt="einvestment"
              class="ml-3">
          </h2>
          <p class="color-text--secondary-light mt-7 mb-4 mb-sm-14 px-6 text-center">Please sign in to access your investor's portal</p>
          <v-form @submit="handleLogin" class="px-6">
            <v-text-field
              id="email"
              v-model="email"
              :error-messages="errors.email"
              append-icon="mdi-email-outline"
              label="Email"
              solo
              :rules="[v=> !!v || 'The email field is required']"
              required
              class="mb-6 mb-sm-7"
              @keydown.enter="handleLogin"
              type="email">
              <template #append>
                <v-icon class="custom">mdi-email-outline</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Password"
              solo
              :rules="[v=> !!v || 'The password field is required']"
              required
              @click:append="() => (show = !show)"
              :error-messages="errors.password"
              @keydown.enter="handleLogin"
            />
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <v-checkbox v-model="remember" />
                <span>Remember Me</span></div>
              <div>
                <router-link :to="{name: 'forgot-password'}">Forgot Password?</router-link>
              </div>
            </div>
            <div class="text-center mt-3">
              <v-btn
                @click="handleLogin"
                color="primary"
                style="width: 70%"
                :disabled="formProcessing"
                :loading="formProcessing"
                large>LOGIN
              </v-btn>
              <p class="color-text--secondary-light mt-8">New on our platform?
                <router-link :to="{name: 'sign-up'}">Create an account</router-link>
              </p>
            </div>
            <v-row
              wrap
              no-gutters
              align="center"
              class="mt-1 mb-2 mb-sm-8 mt-sm-8">
              <v-divider class="mr-4"/>
              or
              <v-divider class="ml-4"/>
            </v-row>
            <div class="d-flex flex-wrap">
              <v-col cols="2">

              </v-col>
              <v-col cols="2">

              </v-col>
              <v-col cols="4">
                <v-btn
                  x-large
                  @click="handleSocialLogin('google')"
                  color="lighten"
                  elevation="0"
                  class="minimized-btn"
                >
                  <img src="@/assets/img/icons/google.svg" alt="google"/>
                </v-btn>
              </v-col>
              <!--            <v-col cols="4">-->
              <!--              <v-btn-->
              <!--                x-large-->
              <!--                @click="handleSocialLogin('facebook')"-->
              <!--                color="lighten"-->
              <!--                elevation="0"-->
              <!--                class="minimized-btn"-->
              <!--              >-->
              <!--                <img src="@/assets/img/icons/facebook.svg" alt="facebook"/>-->
              <!--              </v-btn>-->
              <!--            </v-col>-->
<!--              <v-col cols="4">-->
<!--                <v-btn-->
<!--                  x-large-->
<!--                  @click="handleSocialLogin('apple')"-->
<!--                  color="lighten"-->
<!--                  elevation="0"-->
<!--                  class="minimized-btn"-->
<!--                >-->
<!--                  <img src="@/assets/img/icons/apple.svg" alt="apple"/>-->
<!--                </v-btn>-->
<!--              </v-col>-->
              <v-col cols="2">

              </v-col>
              <v-col cols="2">

              </v-col>
            </div>
          </v-form>
        </v-card>
      </transition>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from "../../layouts/AuthLayout"

export default {
  name: "Login",
  components: {
    AuthLayout,
  },
  data: function () {
    return {
      email: '',
      password: '',
      remember: false,
      errors: {},
      show: false,
      formProcessing: false,
      loader: true,
    }
  },
  created() {
    //
  },
  mounted() {
    this.loader = false
  },
  methods: {
    async handleLogin() {
      const {email, password, remember} = this
      this.formProcessing = true
      this.$auth.login({data: {email, password, remember}, redirect: null})
        .then(res => {
          let route = res.data.data.roles?.includes(process.env.VUE_APP_ADMIN_ROLE_NAME)
            ? this.$auth.options.loginData.redirect.admin
            : this.$auth.options.loginData.redirect.client

          this.$router.push(route)
        })
        .catch((error) => {
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.formProcessing = false
        })
    },
    handleSocialLogin(type) {
      switch (type) {
      case 'google':
        window.location.href = `${process.env.VUE_APP_URL}/oauth/google/auth`
        break
      case 'facebook':
        //
        break
      case 'apple':
        //
        break
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.login-card {
  width: 100%;
}

.login-header {
  img {
    height: 32px;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    img {
      height: 24px;
    }
  }
}
</style>
